import React from "react"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  return (
    <Layout>
      <Seo title="Clove Blog | Customer Success and Company Culture" />

      <div className={"relative bg-white py-16"}>
        <div className={"mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl"}>
          <h2 className="heading-primary">Blog</h2>

          <div className="mt-12">
            {
              edges.map(edge => (
                <div key={edge.node.id} className="mb-20 last:m-0">
                  <div className="text-secondary uppercase tracking-wide">{edge.node.frontmatter.category} | {edge.node.frontmatter.readTime}</div>

                  <div className="mt-4 block md:flex">
                    <div className="flex-none w-full md:w-7/12">
                      <h3 className="heading-secondary text-2xl">
                        <Link to={edge.node.frontmatter.slug}>
                          {edge.node.frontmatter.title}
                        </Link>
                      </h3>
                      <div className="mt-3 text-secondary">
                        <p>{edge.node.frontmatter.seoDescription}</p>
                        <p className="mt-2">
                          <Link className="underline text-brand-500" to={edge.node.frontmatter.slug}>Read Post</Link>
                        </p>
                      </div>
                    </div>

                    <div className="flex-grow md:pl-8 mt-4 md:mt-0">
                      <Img alt={edge.node.frontmatter.featuredImageAlt} fluid={edge.node.frontmatter.featuredImage.childImageSharp.fluid} />
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            category
            readTime
            seoDescription
            featuredImageAlt
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
